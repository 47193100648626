<template>
  <div class="popup">
    <span class="title">ボケてバトルについて</span>
    <img class="logo" src="~/assets/img/world/use_top.png"/>
    <span class="sub">ボケてバトルとは</span>
    <p class="text">ボケてバトルとは、写真で一言ボケて、どっちが面白いかを決めるタイマンお笑いバトルの場。判定するのは第三者の“立会人” 勝つとBR(ボケてレート)が上がり、負けるとBRが下がります。目指せBR全国ランキング1位！</p>
    <span class="sub">遊び方</span>
    <div>
      <span class="section-title">●バトルする場合</span>
      <ol class="text">
      <li>お題写真が出ます</li>
      <li>時間内にボケます</li>
      <li>立会人が判定します</li>
      <li>対戦結果を受け入れます</li>
      </ol>
    </div>
    <div>
      <span class="section-title">●立ち会う場合</span>
      <ol class="text">
        <li>お題写真が出ます</li>
        <li>ボケを待ちます</li>
        <li>待っている間に過去のボケてバトルの判定チェックをします</li>
        <li>ボケを判定します</li>
        <li>対戦結果を見ます</li>
      </ol>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.popup{
  position: relative;
  background: #fff;
  height: 80%;
  width: 90%;
  padding: 25px;
  border-radius: 10px;
  margin: 0 auto;
  overflow-y: auto;
  border: 6px solid #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;;
  gap: 20px;
  &::-webkit-scrollbar {
    display: none; 
  }
  >.title{
    font-size: 20px;
    font-weight: bold;
    align-self: center;
  }
  >.logo{
    align-self: center;
    height: 40px;
  }
  >.sub{
    font-size: 18px;
    font-weight: bold;
  }
  >.text,>*>.text{
    font-size: 14px;
    margin-top: 5px;
    line-height: 1.5;
    font-weight: bold;
  }
  >*>.text{
    margin-left: 20px;
  }
  >*>.section-title{
    font-size: 16px;
    font-weight: bold;
  }
}
</style>