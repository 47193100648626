<template>
    <div class="top">
        <div class="left" >
                <img src="~/assets/img/room/menu.png" > 
                <v-menu activator="parent">
                    <v-list>
                        <v-list-item  v-for="(item, index) in newMenuList" :key="index" :value="index" @click="tap(item.index)">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
        </div>
        <div class="logo">
            <img src="~/assets/img/world/titleLogo.png"/>
        </div>
        <div v-if="showProfile" class="right" @click="$emit('openProfile')">
            <v-img
            class="profile"
            :aspect-ratio="1"
            :src="userIcon"
            transition="true"
            cover
            >
            </v-img>
        </div>
        <v-dialog v-model="showWaring" max-width="240">
        <v-card>
            <v-card-text>
                現在のバトルを強制終了しますか？ 
                参加者に通知してバトルを強制終了します。
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="showWaring = false">キャンセル</v-btn>
            <v-btn color="red darken-1" text @click="forceExit">強制終了</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!--    class="dialog-background"-->
    <v-dialog 
      v-model="showUse"
      max-width="400"
      max-height="90%"
    >
      <CommonWorldUse></CommonWorldUse>
    </v-dialog>
    <v-dialog 
      v-model="showInquiry"
      max-width="400"
      max-height="90%"
    >
      <CommonWorldInquiry></CommonWorldInquiry>
    </v-dialog>
    </div>
</template>
<script setup>
import { storeToRefs } from 'pinia'
const props = defineProps({
    showProfile: {
        type: Boolean,
        default: false
    },
    icon: {
        type: String,
        default: ""
    }
})
defineEmits(["openProfile"]);

const showWaring = ref(false);

const config = useRuntimeConfig()
const battle = useBattleStore();
const showUse = ref(false);
const showInquiry = ref(false);
const { battle_status } = storeToRefs(battle);

const userIcon = computed(() => {
    if(props.icon){
        return props.icon;
    }else{
        return "/img/iconDefault.png";
    }
})


const newMenuList = computed(()=>{
    let re_menulist = menulist;
    if(battle.battle_info.battle_chan===""&&battle.battle_info.status===BattleStatus.unkonwn){
        re_menulist = re_menulist.filter(item=>item.index !== 2);
    }
    if(battle.battle_info.battle_chan!==""){
        if(battle.battle_info.status===BattleStatus.matching||
        battle.battle_info.status===BattleStatus.readying||
        battle.battle_info.status===BattleStatus.odaiing||
        battle.battle_info.status===BattleStatus.battling||
        battle.battle_info.status===BattleStatus.evaluating)
        re_menulist = re_menulist.filter(item=>item.index !== 0);
    }
    if(battle.battle_info.status===BattleStatus.finished||
        battle.battle_info.status===BattleStatus.userstop||
        battle.battle_info.status===BattleStatus.forcestop){
        re_menulist = re_menulist.filter(item=>item.index !== 2);
    }
    if(battle.battle_info.status===BattleStatus.unkonwn){
        re_menulist = re_menulist.filter(item=>item.index !== 2);
    }

    return re_menulist;
})

function tap(index){
    if(index == 0){
        navigateTo('/')
        
    }else if(index == 1){
        //使い方
        showUse.value = true;
    }else if(index == 2){
        showWaring.value = true;
        //バトル強制終了
    }else if(index == 3){
        //問い合わせ
        showInquiry.value = true;
    }
}

const showForceExit = computed(() => {
    if(battle.battle_info.battle_chan!=""
    &&battle.battle_info.status!==BattleStatus.finished
    &&battle.battle_info.status!==BattleStatus.userstop
    &&battle.battle_info.status!==BattleStatus.forcestop)
    {
        return true;
    }else{
        return false;
    }
});

function forceExit(){
    useForceExit(battle);
    showWaring.value = false;
}

</script>
<style lang="scss" scoped>

.top {
    text-align: center;
    position: sticky;
    width: 100%;
    top: 0px;
    padding:20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background: $base-room-color;
    // background-color: yellow;
    // height: 120px;

    >.left{
        flex: 0 0.4 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            width: 100%;
            max-width: 26px;
        }
    }
    >.logo{
        flex: 2 1 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            width: 100%;
            max-width: 158px;
        }
    }
    >.right{
        flex: 0 0.3 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.3));
        overflow: hidden;
        > :deep(.profile) {
            border-radius: 9999px;
            height: 30px;
            width: 30px;
            overflow: hidden;
            border: 2px solid #FFF;
        }
    }
}
</style>